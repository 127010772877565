import { useState } from 'react';
import CardHNav from 'src/components/cards/card-h-nav';
import { arrayEmpty } from 'src/utils/helper';
import {
  DATALAYER_TYPE,
  sendDataLayerGlobal
} from 'src/services/member-auth/datalayer/send-datalayer';
import { useRouter } from 'next/router';
const CardSubMenu = ({ data, menu }) => {
  if (arrayEmpty(data)) return null;
  const router = useRouter();
  const [articles, setArticles] = useState({
    key: null,
    data: data[0]?.data,
    link: data[0]?.link
  });
  // console.log('CardSubMenu', data);
  return (
    <>
      <div className='card-wrapper'>
        <div className='row card-sub-menu'>
          <div className='col-4'>
            <ul>
              {!arrayEmpty(data) &&
                data.map((item, index) => (
                  <a
                    key={index}
                    href={item.link}
                    title={item.nameTh}
                    //! DATALAYER
                    onClick={() =>
                      sendDataLayerGlobal({
                        type: DATALAYER_TYPE.TRACK_POSITION,
                        router: router?.pathname,
                        position: 'ใน:menubar',
                        section: 'header',
                        data: {
                          title: item?.nameTh,
                          heading: `${menu?.nameTh}:${item?.nameTh}`
                        }
                      })
                    }
                  >
                    <li
                      aria-hidden='true'
                      onMouseOver={() => {
                        setArticles({
                          key: index,
                          data: item.data,
                          link: item.link
                        });
                      }}
                      onFocus={() => {}}
                    >
                      {item.nameTh}
                    </li>
                  </a>
                ))}
            </ul>
          </div>
          <div className='col-8 card-sub-menu-content'>
            {!arrayEmpty(articles?.data) &&
              articles.data.map((item, index) => (
                <CardHNav key={index} data={item} path={item.link} />
              ))}
            <button className='btn-readmore'>
              <a className='see-all' href={articles?.link}>
                SEE ALL
              </a>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
export default CardSubMenu;
