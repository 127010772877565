import { useRef, useState } from 'react';
import { FaSearch } from 'react-icons/fa';
import { CgClose } from 'react-icons/cg';
import ReCAPTCHA from 'react-google-recaptcha';
import qs from 'qs';
import { isRender } from 'src/utils/device';
import { RECAPTCHA_SIT_KEY, SHOW_COMMEMORATE } from 'src/constants';
import { convertObjPathNoneEncode } from 'src/utils/helper';

const SearchBox = () => {
  const [toggle, setToggle] = useState(false);
  const [textSearch, setTextSearch] = useState(null);
  const [recaptchaBox, setRecaptchaBox] = useState(false);

  const searchRef = useRef();
  const recaptchaRef = useRef(null);

  function clearToggle () {
    setToggle(false);
    setRecaptchaBox(false);
    setTextSearch(null);
    document.getElementById('search-form').reset();
  }

  function handleToggle (_key) {
    // console.log(_key);
    if (_key === 'close') {
      clearToggle();
    } else {
      setToggle(!toggle);
    }
  }

  function handleSearch (e) {
    e.preventDefault();
    setRecaptchaBox(true);
  }

  const onReCAPTCHAChange = async (captchaCode) => {
    // console.log({ captchaCode });
    if (!captchaCode) return null;

    try {
      const response = await fetch('/api/search', {
        method: 'POST',
        body: JSON.stringify({ textSearch, captcha: captchaCode }),
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (response.ok) {
        setRecaptchaBox(false);
        const serachParams = convertObjPathNoneEncode({
          q: encodeURIComponent(textSearch || '')
        });

        const params = qs.stringify(serachParams, {
          encodeValuesOnly: true,
          arrayFormat: 'brackets'
        });
        window.location.href = `/search?${params}`;
      } else {
        const error = await response.json();
        throw new Error(error.message);
      }
    } catch (err) {
      alert(err?.message || 'Something went wrong');
      setRecaptchaBox(false);
    } finally {
      recaptchaRef.current = null;
      clearToggle();
    }
  };

  // console.log({ recaptchaBox, textSearch, searchRef });
  return (
    <>
      <div className='search-box' style={{ filter: SHOW_COMMEMORATE === 'true' ? 'grayscale(1)' : 'unset' }}>
        <form
          id='search-form'
          className={`search-form ${toggle ? 'visible' : 'invisible'}`}
          ref={searchRef}
          onSubmit={handleSearch}
        >
          <input
            id='search-input'
            className='search-input'
            placeholder='Search...'
            onChange={(e) => setTextSearch(e.target.value)}
          />
          {
            isRender('mobile') &&
            <button className='search-btn-mobile p-3 mt-1 w-100' type='submit' onClick={() => handleToggle('open')} aria-label='Search Submit'>
              ค้นหา
            </button>
          }
        </form>
        <button className='search-btn' aria-label='Search'>
          {toggle ? <CgClose onClick={() => handleToggle('close')} /> : <FaSearch onClick={() => handleToggle('open')} /> }
        </button>
      </div>
      <div className={`search-wrapper ${recaptchaBox ? 'visible' : 'invisible'}`} />
      {
        recaptchaBox && <div className='recaptcha-box' style={{ filter: SHOW_COMMEMORATE === 'true' ? 'grayscale(1)' : 'unset' }}>
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey={RECAPTCHA_SIT_KEY}
            onChange={onReCAPTCHAChange}
          />
        </div>
      }
    </>
  );
};

export default SearchBox;
