import MobileDetect from 'mobile-detect';
import { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';

export const getDevice = () => {
  if (typeof window !== 'undefined') {
    const mobileDetect = new MobileDetect(window.navigator.userAgent);
    if (mobileDetect.phone()) {
      return 'mobile';
    } else {
      return 'desktop';
    }
  }
  return null;
};

export const isRender = (device) => {
  return device === 'all' || device === getDevice();
};

export const useResponsive = () => {
  const [isClient, setIsClient] = useState(false);

  const isMobile = useMediaQuery({
    maxWidth: '690px'
  });

  const isTabletMini = useMediaQuery({
    maxWidth: '768px'
  });
  const isTabletPro = useMediaQuery({
    minWidth: '769px'
  });
  const isDesktopMini = useMediaQuery({
    minWidth: '961px'
  });
  const isMobileMini = useMediaQuery({
    maxWidth: '960px'
  });

  const isLaptop = useMediaQuery({
    minWidth: '691px'
  });

  const isDesktop = useMediaQuery({
    minWidth: '1024px'
  });

  useEffect(() => {
    if (typeof window !== 'undefined') setIsClient(true);
  }, []);

  return {
    isDesktop: isClient ? isDesktop : true,
    isLaptop: isClient ? isLaptop : true,
    isDesktopMini: isClient ? isDesktopMini : true,
    isMobileMini: isClient ? isMobileMini : true,
    isTabletMini: isClient ? isTabletMini : false,
    isTabletPro: isClient ? isTabletPro : false,
    isMobile: isClient ? isMobile : false
  };
};
