import dynamic from 'next/dynamic';
import { FaGripHorizontal, FaTimes } from 'react-icons/fa';
import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import MenuExpandMore from 'src/components/layouts/menu-expand-more';
import SearchBox from 'src/components/search-box';
import { useAppSelector } from 'src/redux/store';
import WidgetLogin from 'src/services/member-auth/components/widget-login';
import { SHOW_COMMEMORATE } from 'src/constants';

const HeaderLogo = dynamic(import('src/components/layouts/header-logo'));
const NavDesktop = dynamic(import('src/components/layouts/nav-desktop'));
const NavMobile = dynamic(import('src/components/layouts/nav-mobile'));

const Header = ({ sessionSevId }) => {
  const data = useAppSelector((props) => props.navData.result);

  const [moreMenu, setMoreMenu] = useState(true);
  const moreMenuRef = useRef();
  function useOnClickOutsideBox (ref, handler) {
    useEffect(
      () => {
        const listener = event => {
          if (!ref.current || ref.current.contains(event.target)) {
            return;
          }

          handler(event);
        };

        document.addEventListener('mousedown', listener);
        document.addEventListener('touchstart', listener);
        document.addEventListener('mouseleave', listener);

        return () => {
          document.removeEventListener('mousedown', listener);
          document.removeEventListener('touchstart', listener);
          document.removeEventListener('mouseleave', listener);
        };
      },
      [ref, handler]
    );
  }
  useOnClickOutsideBox(moreMenuRef, async () => {
    await setMoreMenu(true);
  });
  const cloneMenuMore = data && [...data].slice(7);
  return (
    <header >
      <Widgetheader>
      {/* ------------------------------- HEADER TOP ------------------------------- */}
      <section className={SHOW_COMMEMORATE === 'true' ? 'header-top-fixed header-gray' : 'header-top-fixed'} >
        <div className='container header-top' >
          <HeaderLogo />
          {!moreMenu && <div ref={moreMenuRef}><MenuExpandMore moreMenu={moreMenu} data={cloneMenuMore} /></div>}
          <div className ={'header-menus '}>
            <NavMobile data={data} sessionSevId={sessionSevId} />
            <NavDesktop data={data} />
          </div>
          {/* --------------------------------- SEARCH --------------------------------- */}
          <div className={'header-action'}>
          {data && data.length > 8 && <ul className='more-menu ' >
            <>
              {
                moreMenu
                  ? <li onClick={() => setMoreMenu(false)}> <div style={{ filter: SHOW_COMMEMORATE === 'true' ? 'grayscale(1)' : 'unset' }}><FaGripHorizontal size={18} /></div></li>
                  : <li onClick={() => setMoreMenu(true)}><div style={{ filter: SHOW_COMMEMORATE === 'true' ? 'grayscale(1)' : 'unset' }}><FaTimes size={18} /></div></li>
              }
            </>
            </ul>}
            <ul className='action-box'>
              <li className='action-item'>
                <p >
                  <SearchBox />
                </p>
              </li>
              <li>
              <WidgetLogin sessionSevId={sessionSevId} />

              </li>
            </ul>

          </div>
        </div>
      </section>
      </Widgetheader>
    </header>
  );
};
const Widgetheader = styled.div`
 .header-gray{
  background-color: #070707;
  border-bottom: 3px solid gray;
    }
`;
export default Header;
