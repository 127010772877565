import { FaCalendarAlt } from 'react-icons/fa';
import { useRouter } from 'next/router';
import Image from 'src/components/image';
import { useDateUpdate } from 'src/utils/helper';
import {
  DATALAYER_TYPE,
  sendDataLayerGlobal
} from 'src/services/member-auth/datalayer/send-datalayer';
const CardHNav = ({ data, path }) => {
  if (!data) return null;
  const router = useRouter();
  const dateUpdate = useDateUpdate(data?.published_at);
  return (
    <div className='card-h-nav'>
      <div className='row align-items-center'>
        <div className='col-4 thumbnail'>
          <a
            href={path}
            title={data.title}
            //! DATALAYER
            onClick={() =>
              sendDataLayerGlobal({
                type: DATALAYER_TYPE.TRACK_POSITION,
                router: router?.pathname,
                position: 'ใน:menubar',
                section: 'header',
                data: {
                  title: `${data?.title}`,
                  heading: `${data?.section?.th}:${data?.subCategory?.th || ''}`
                }
              })
            }
          >
            <Image image={data.image} title={data.title} />
          </a>
        </div>
        <div className='col-8 title'>
          <a href={path} title={data.title}
           //! DATALAYER
           onClick={() =>
             sendDataLayerGlobal({
               type: DATALAYER_TYPE.TRACK_POSITION,
               router: router?.pathname,
               position: 'ใน:menubar',
               section: 'header',
               data: {
                 title: `${data?.title}`,
                 heading: `${data?.section?.th}:${data?.subCategory?.th || ''}`
               }
             })
          }

          >
            <h3>{data.title}</h3>
          </a>
          <div>
            <FaCalendarAlt size={12} />
            <span>{dateUpdate}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardHNav;
